import * as React from 'react';

import { Route, isBranchManager, Customer, isPlatformAdministrator, isCompanyManager, isSuperUser } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/branch/customers',
        main: () => import('./CustomersOverview'),
        isAuthorized: [isBranchManager],
        preloadData: Customer.overviewState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/branch/customers/:identifiedCustomerId',
        main: () => import('./ViewCustomer'),
        isAuthorized: [isBranchManager],
        preloadData: Customer.detailsState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/branch/customers/:identifiedCustomerId/:tab',
        main: () => import('./ViewCustomer'),
        isAuthorized: [isBranchManager],
        preloadData: Customer.detailsState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/branch/customers/new',
        main: () => import('./CreateCustomer'),
        isAuthorized: [isBranchManager],
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/branch/customers/edit/:identifiedCustomerId',
        main: () => import('./UpdateCustomer'),
        isAuthorized: [isBranchManager],
        preloadData: Customer.updateState.actions.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/branch/customers/print/:identifiedCustomerId',
        main: () => import('./PrintCustomerCode'),
        isAuthorized: [isBranchManager, isPlatformAdministrator, isCompanyManager, isSuperUser],
        preloadData: Customer.detailsState.actions.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
