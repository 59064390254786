import * as React from 'react';
import { Link } from 'react-router-dom';

import Button, { ButtonSize, ButtonVariant } from './Button';

export default function LinkButton(props: {
    to: string;
    className?: string;
    children?: React.ReactNode;
    size?: ButtonSize;
    variant?: ButtonVariant;
    onClick?: (event: React.MouseEvent) => void;
    disabled?: boolean;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    target?: string;
    'data-test-id'?: string;
}) {
    return (
        <Button
            className={props.className}
            variant={props.variant || 'primary'}
            variantSize={props.size}
            as={Link}
            to={props.to}
            onClick={props.onClick}
            disabled={props.disabled}
            endIcon={props.endIcon}
            startIcon={props.startIcon}
            data-test-id={props['data-test-id']}
            target={props.target}
        >
            {props.children}
        </Button>
    );
}
